<template>
  <b-modal
    ref="modal-team"
    modal-class="modal-default modal-team"
    size="xl"
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <b-link class="modal-close" @click="close()">
        <b-img
          :src="`${publicPath}assets/images/modal-close.png`"
          alt=""
        ></b-img>
      </b-link>
    </template>
    <template v-slot:default>
      <b-row>
        <b-col md="3">
          <div class="team-img-wrap">
            <b-img
              :src="`${publicPath}assets/images/team/${teamMember.img}?v=1`"
              width="140"
              height="140"
              alt=""
            ></b-img>
          </div>
        </b-col>
        <b-col md="9">
          <div class="team-content">
            <h3 class="team-name">{{ teamMember.name }}</h3>
            <span class="team-title">{{ teamMember.title }}</span>
            <div class="team-about" v-html="teamMember.about"></div>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      teamMember: {}
    };
  },
  mounted() {
    this.$eventHub.$on("modalTeamOpen", team => {
      this.teamMember = team;
      this.$refs["modal-team"].show();
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("modalTeamOpen");
  }
};
</script>
