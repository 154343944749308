<template>
  <section class="our-story-team">
    <div class="our-story-team-swiper">
      <h2 class="section-title" ref="teamTitle">
        {{ $t("ourStoryPage.leadership") }}
      </h2>
      <div
        v-swiper:mobileTeamSwiper="swiperOptionsTeam"
        @slide-change-transition-start="sliderChanged"
      >
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(team, tIndex) in leadership"
            :key="`l${tIndex}`"
          >
            <div class="our-story-team-content">
              <div class="team-item" @click="openTeamModal(team)">
                <b-link class="team-img-wrap">
                  <b-img
                    :src="`${publicPath}assets/images/team/${team.img}?v=1`"
                    alt=""
                  ></b-img>
                </b-link>
                <div class="team-content">
                  <h3 class="team-name">{{ team.name }}</h3>
                  <span class="team-title">{{ team.title }}</span>
                  <b-link v-if="team.about" class="team-read-more"
                    >Read full bio</b-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="swiper-slide"
            v-for="(team, tIndex) in directors"
            :key="`d${tIndex}`"
          >
            <div class="our-story-team-content our-story-team-directors">
              <div class="team-item" @click="openTeamModal(team)">
                <b-link class="team-img-wrap">
                  <b-img
                    :src="`${publicPath}assets/images/team/${team.img}?v=1`"
                    alt=""
                  ></b-img>
                </b-link>
                <div class="team-content">
                  <h3 class="team-name">{{ team.name }}</h3>
                  <b-link v-if="team.about" class="team-read-more"
                    >Read full bio</b-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalTeam></ModalTeam>
  </section>
</template>

<script>
import { directive } from "vue-awesome-swiper";

import "swiper/css/swiper.css";
import ModalTeam from "@/components/Modal/ModalTeam.vue";

export default {
  components: {
    ModalTeam
  },
  directives: {
    swiper: directive
  },
  mounted() {
    let team = this.$t("ourStoryPage.team");

    this.leadership.push(
      team.GaryMekikian,
      team.BobVanech,
      team.CoreyJones,
      team.LuisSoto,
      team.KevinMills,
      team.AramMesrobyan,
      team.CamilaVargas,
      team.TigranPetrosyan
    );

    this.directors.push(
      team.GaryMekikian,
      team.TigranMekikian,
      team.AdrianSada,
      team.KevinCrowley
    );
  },
  data() {
    return {
      leadership: [],
      directors: [],
      swiperOptionsTeam: {
        slidesPerView: 1,
        breakpoints: {
          319: {
            slidesPerView: 2
          },
          380: {
            slidesPerView: 2
          },
          480: {
            slidesPerView: 2.4
          },
          640: {
            slidesPerView: 3.4
          },
          768: {
            slidesPerView: 4.4
          }
        }
      }
    };
  },
  methods: {
    openTeamModal(team) {
      this.$eventHub.$emit("modalTeamOpen", team);
    },

    sliderChanged() {
      if (this.mobileTeamSwiper.activeIndex < this.leadership.length) {
        this.$refs["teamTitle"].innerHTML = this.$t("ourStoryPage.leadership");
      } else {
        this.$refs["teamTitle"].innerHTML = this.$t(
          "ourStoryPage.boardOfDirectors"
        );
      }
    }
  }
};
</script>
