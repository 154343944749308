<template>
  <div>
    <section class="our-story-head">
      <div
        class="our-story-head-bg"
        :style="{
          'background-image':
            'url(' + `${publicPath}assets/images/our-story-head-bg.png` + ')'
        }"
      ></div>
      <div
        class="mobile-our-story-head-bg"
        :style="{
          'background-image':
            'url(' +
            `${publicPath}assets/images/mobile-our-story-head-bg.png` +
            ')'
        }"
      ></div>
      <div class="our-story-head-inner">
        <div class="container">
          <div class="our-story-head-content">
            <h1
              class="head-title"
              v-html="$t('ourStoryPage.globalMarket')"
            ></h1>
          </div>
        </div>
      </div>
    </section>
    <section class="our-story" id="about">
      <h2 class="section-title">{{ $t("ourStoryPage.ourStory") }}</h2>
      <div class="container">
        <div
          class="our-story-content"
          v-html="$t('ourStoryPage.ourStoryBody')"
        ></div>
      </div>
    </section>

    <UiTeam v-if="teamLayout === 'desktop'"></UiTeam>
    <UiTeamMobile v-else-if="teamLayout === 'mobile'"></UiTeamMobile>

    <!--    <UiTeam v-if="teamLayout === 'dsesktop'"></UiTeam>-->
    <!--    <UiTeamMobile v-else></UiTeamMobile>-->

    <section class="our-story-media" id="media-mentions">
      <h2 class="section-title">{{ $t("ourStoryPage.mediaMentions") }}</h2>
      <div class="our-story-media-content">
        <swiper class="swiper" :options="swiperOptionsMedia">
          <swiper-slide
            class="swiper-slide"
            v-for="(media, tIndex) in mediaList"
            :key="tIndex"
          >
            <b-link @click="modalVideoOpen(media)" class="media-item-wrap">
              <span
                class="media-img-wrap"
                :style="{
                  'background-image':
                    'url(' +
                    `${publicPath}assets/images/thumbnail/${media.pic}` +
                    ')'
                }"
              >
                <b-img
                  :src="`${publicPath}assets/images/media-play.png`"
                  alt=""
                  class="media-play"
                ></b-img>
              </span>
              <span class="media-content">
                <h3 class="media-title">{{ media.title }}</h3>
                <span class="media-subtitle" v-html="media.subTitle"></span>
              </span>
            </b-link>
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <section class="our-story-form" id="contact-us">
      <h2 class="section-title">{{ $t("ourStoryPage.forMedia") }}</h2>
      <div class="container">
        <div class="our-story-form-content">
          <ValidationObserver
            ref="formAboutContactUs"
            v-slot="{ handleSubmit }"
          >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <b-row>
                <b-col sm="12" md="6">
                  <b-form-group
                    id="input-group-full-name"
                    :label="$t('form.contact.fullName')"
                    label-for="input-full-name"
                  >
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                      :name="$t('form.contact.fullName')"
                    >
                      <b-form-input
                        id="input-full-name"
                        v-model="form.fullName"
                        type="text"
                      ></b-form-input>
                      <span v-if="errors[0]" class="label-error">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6">
                  <b-form-group
                    id="input-group-email"
                    :label="$t('form.contact.emailAddress')"
                    label-for="input-email"
                  >
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                      :name="$t('form.contact.emailAddress')"
                    >
                      <b-form-input
                        id="input-email"
                        v-model="form.email"
                        type="text"
                      ></b-form-input>
                      <span v-if="errors[0]" class="label-error">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-message"
                    :label="$t('form.contact.yourMessage')"
                    label-for="input-message"
                  >
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                      :name="$t('form.contact.yourMessage')"
                    >
                      <b-form-textarea
                        id="input-message"
                        v-model="form.message"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <span v-if="errors[0]" class="label-error">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="btn-submit-wrap form-group text-right">
                <button
                  type="submit"
                  class="btn btn-submit ml-auto"
                  :disabled="!allowSubmit"
                >
                  {{ $t("form.contact.submit") }}
                </button>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </div>
    </section>

    <ModalVideo></ModalVideo>
  </div>
</template>

<script>
import ModalVideo from "@/components/Modal/ModalVideo.vue";
import UiTeam from "@/components/UI/UiTeam.vue";
import UiTeamMobile from "@/components/UI/UiTeamMobile.vue";
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";

import { ValidationObserver, ValidationProvider } from "vee-validate";

import "swiper/css/swiper.css";

export default {
  metaInfo() {
    return {
      title: this.$t("meta.about.title"),
      titleTemplate: null,
      htmlAttrs: {
        lang: this.$t("lang")
      },
      meta: this.generateMeta({
        title: this.$t("meta.about.title"),
        description: this.$t("meta.about.description"),
        url: this.generateUrl(this.locale)
      })
    };
  },
  components: {
    //d ModalTeam,
    ModalVideo,
    ValidationObserver,
    ValidationProvider,
    Swiper,
    SwiperSlide,
    UiTeam,
    UiTeamMobile
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      mediaList: [],
      banners: ["/1.jpg", "/2.jpg", "/3.jpg"],

      swiperOptionsMedia: {
        breakpoints: {
          1100: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          576: {
            slidesPerView: 1,
            centeredSlides: true
          }
        }
      },
      form: {
        fullName: "",
        email: "",
        message: ""
      },
      allowSubmit: true,
      teamLayout: "desktop"
    };
  },
  computed: {
    locale() {
      return this.$store.getters.locale;
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);

    this.mediaList = this.$t("ourStoryPage.media");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      let width = window.innerWidth;
      if (width <= 768) {
        this.teamLayout = "mobile";
      } else {
        this.teamLayout = "desktop";
      }
    },
    modalVideoOpen(media) {
      this.$eventHub.$emit("modalVideoOpen", media);
    },
    onSubmit() {
      this.$refs.formAboutContactUs.validate().then(success => {
        if (!success) {
          return;
        }

        this.allowSubmit = false;

        let message = `Full name: ${this.form.fullName}\n`;
        message += `Email address: ${this.form.email}\n`;
        message += `Language: ${this.$i18n.locale === "mx" ? "ES" : "EN"}\n\n`;
        message += this.form.message;

        const data = {
          name: this.form.fullName,
          email: this.form.email,
          subject: "Website Contact: Media or Partnership Inquiries",
          message: message,
          sendTo: "hello@trebelmusic.com",
          cc: "camila@trebel.io"
        };

        this.$axios
          .post(process.env.VUE_APP_SEND_EMAIL_URL, this.$qs.stringify(data), {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
          })
          .then(() => {
            this.allowSubmit = true;
            this.$swal({
              customClass: {
                cancelButton: "btn-submit",
                container: "swal2-custom swal2-custom-success"
              },
              buttonsStyling: false,
              showCloseButton: true,
              showCancelButton: true,
              showConfirmButton: false,
              title: this.$t("form.contact.thankYou"),
              text: this.$t("form.contact.weWillBeInTouch"),
              cancelButtonText: this.$t("form.contact.close")
            });

            this.form.fullName = this.form.email = this.form.message = "";

            this.$nextTick(() => {
              this.$refs.formAboutContactUs.reset();
            });
          })
          .catch(() => {
            this.allowSubmit = true;
          });
      });
    }
  }
};
</script>
