var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"our-story-head"},[_c('div',{staticClass:"our-story-head-bg",style:({
        'background-image':
          'url(' + _vm.publicPath + "assets/images/our-story-head-bg.png" + ')'
      })}),_c('div',{staticClass:"mobile-our-story-head-bg",style:({
        'background-image':
          'url(' +
          _vm.publicPath + "assets/images/mobile-our-story-head-bg.png" +
          ')'
      })}),_c('div',{staticClass:"our-story-head-inner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"our-story-head-content"},[_c('h1',{staticClass:"head-title",domProps:{"innerHTML":_vm._s(_vm.$t('ourStoryPage.globalMarket'))}})])])])]),_c('section',{staticClass:"our-story",attrs:{"id":"about"}},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("ourStoryPage.ourStory")))]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"our-story-content",domProps:{"innerHTML":_vm._s(_vm.$t('ourStoryPage.ourStoryBody'))}})])]),(_vm.teamLayout === 'desktop')?_c('UiTeam'):(_vm.teamLayout === 'mobile')?_c('UiTeamMobile'):_vm._e(),_c('section',{staticClass:"our-story-media",attrs:{"id":"media-mentions"}},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("ourStoryPage.mediaMentions")))]),_c('div',{staticClass:"our-story-media-content"},[_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOptionsMedia}},_vm._l((_vm.mediaList),function(media,tIndex){return _c('swiper-slide',{key:tIndex,staticClass:"swiper-slide"},[_c('b-link',{staticClass:"media-item-wrap",on:{"click":function($event){return _vm.modalVideoOpen(media)}}},[_c('span',{staticClass:"media-img-wrap",style:({
                'background-image':
                  'url(' +
                  _vm.publicPath + "assets/images/thumbnail/" + (media.pic) +
                  ')'
              })},[_c('b-img',{staticClass:"media-play",attrs:{"src":(_vm.publicPath + "assets/images/media-play.png"),"alt":""}})],1),_c('span',{staticClass:"media-content"},[_c('h3',{staticClass:"media-title"},[_vm._v(_vm._s(media.title))]),_c('span',{staticClass:"media-subtitle",domProps:{"innerHTML":_vm._s(media.subTitle)}})])])],1)}),1)],1)]),_c('section',{staticClass:"our-story-form",attrs:{"id":"contact-us"}},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("ourStoryPage.forMedia")))]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"our-story-form-content"},[_c('ValidationObserver',{ref:"formAboutContactUs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-full-name","label":_vm.$t('form.contact.fullName'),"label-for":"input-full-name"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('form.contact.fullName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-full-name","type":"text"},model:{value:(_vm.form.fullName),callback:function ($$v) {_vm.$set(_vm.form, "fullName", $$v)},expression:"form.fullName"}}),(errors[0])?_c('span',{staticClass:"label-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-email","label":_vm.$t('form.contact.emailAddress'),"label-for":"input-email"}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":_vm.$t('form.contact.emailAddress')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-email","type":"text"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(errors[0])?_c('span',{staticClass:"label-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-message","label":_vm.$t('form.contact.yourMessage'),"label-for":"input-message"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('form.contact.yourMessage')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"input-message","rows":"3","max-rows":"6"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),(errors[0])?_c('span',{staticClass:"label-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"btn-submit-wrap form-group text-right"},[_c('button',{staticClass:"btn btn-submit ml-auto",attrs:{"type":"submit","disabled":!_vm.allowSubmit}},[_vm._v(" "+_vm._s(_vm.$t("form.contact.submit"))+" ")])])],1)]}}])})],1)])]),_c('ModalVideo')],1)}
var staticRenderFns = []

export { render, staticRenderFns }