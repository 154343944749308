<template>
  <b-modal
    ref="modal-video"
    modal-class="modal-default modal-video"
    size="xl"
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <b-link class="modal-close" @click="close()">
        <b-img
          :src="`${publicPath}assets/images/modal-close.png`"
          alt=""
        ></b-img>
      </b-link>
    </template>
    <template v-slot:default>
      <div class="modal-video-content">
        <h2 class="media-title">{{ media.title }}</h2>
        <p class="media-subtitle" v-html="media.subTitle"></p>
        <iframe
          width="560"
          height="315"
          :src="`https://www.youtube.com/embed/${media.id}`"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      media: {}
    };
  },
  destroyed() {
    this.media = {};
  },
  mounted() {
    this.$eventHub.$on("modalVideoOpen", media => {
      this.media = media;
      this.$refs["modal-video"].show();
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("modalVideoOpen");
  }
};
</script>
