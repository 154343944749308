<template>
  <section class="our-story-team" id="leadership">
    <div class="our-story-team-swiper">
      <div v-swiper:mySwiper="swiperOptionsTeam">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <h2 class="section-title">{{ $t("ourStoryPage.leadership") }}</h2>
            <div class="our-story-team-content our-story-team-leadership">
              <div class="container">
                <b-row
                  v-for="(grouped, gIndex) in groupedLeadership"
                  class="team-list"
                  :key="gIndex"
                >
                  <b-col v-for="(team, tIndex) in grouped" :key="tIndex">
                    <div class="team-item" @click="openTeamModal(team)">
                      <b-link class="team-img-wrap">
                        <b-img
                          :src="
                            `${publicPath}assets/images/team/${team.img}?v=1`
                          "
                          alt=""
                        ></b-img>
                      </b-link>
                      <div class="team-content">
                        <h3 class="team-name">{{ team.name }}</h3>
                        <span class="team-title">{{ team.title }}</span>
                        <b-link v-if="team.about" class="team-read-more"
                          >Read full bio</b-link
                        >
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <h2 class="section-title">
              {{ $t("ourStoryPage.boardOfDirectors") }}
            </h2>
            <div class="our-story-team-content our-story-team-directors">
              <div class="container">
                <b-row
                  v-for="(grouped, gIndex) in groupedDirectors"
                  class="team-list"
                  :key="gIndex"
                >
                  <b-col v-for="(team, tIndex) in grouped" :key="tIndex">
                    <div class="team-item" @click="openTeamModal(team)">
                      <b-link class="team-img-wrap">
                        <b-img
                          :src="
                            `${publicPath}assets/images/team/${team.img}?v=1`
                          "
                          alt=""
                        ></b-img>
                      </b-link>
                      <div class="team-content">
                        <h3 class="team-name">{{ team.name }}</h3>
                        <b-link v-if="team.about" class="team-read-more">{{
                          $t("ourStoryPage.readFullBio")
                        }}</b-link>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-wrap container">
          <div
            class="team-swiper-button-prev swiper-button-prev"
            :style="{
              'background-image':
                'url(' + `${publicPath}assets/images/prev.png` + ')'
            }"
          ></div>
          <div
            class="team-swiper-button-next swiper-button-next"
            :style="{
              'background-image':
                'url(' + `${publicPath}assets/images/next.png` + ')'
            }"
          ></div>
        </div>
      </div>
    </div>
    <ModalTeam></ModalTeam>
  </section>
</template>

<script>
import { directive } from "vue-awesome-swiper";

import "swiper/css/swiper.css";
import ModalTeam from "@/components/Modal/ModalTeam.vue";

export default {
  components: {
    ModalTeam
  },
  directives: {
    swiper: directive
  },
  mounted() {
    let team = this.$t("ourStoryPage.team");

    let leadership = [];
    let directors = [];

    leadership.push(
      team.GaryMekikian,
      team.BobVanech,
      team.CoreyJones,
      team.LuisSoto,
      team.KevinMills,
      team.AramMesrobyan,
      team.CamilaVargas,
      team.TigranPetrosyan
    );

    this.groupedLeadership = this.chunk(leadership, 4);

    directors.push(
      team.GaryMekikian,
      team.TigranMekikian,
      team.AdrianSada,
      team.KevinCrowley
    );

    this.groupedDirectors = this.chunk(directors, 2);
  },
  data() {
    return {
      groupedLeadership: [],
      groupedDirectors: [],
      swiperOptionsTeam: {
        allowTouchMove: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".team-swiper-button-next",
          prevEl: ".team-swiper-button-prev"
        }
      }
    };
  },
  methods: {
    openTeamModal(team) {
      this.$eventHub.$emit("modalTeamOpen", team);
    },

    chunk(arr, size) {
      var newArr = [];
      for (var i = 0; i < arr.length; i += size) {
        newArr.push(arr.slice(i, i + size));
      }

      return newArr;
    }
  }
};
</script>
